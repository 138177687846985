



























































import { Component, Vue } from 'vue-property-decorator'
import FundedProjectTable from '@/modules/summary/components/funded-project-table.vue'

@Component({
  components: {
    FundedProjectTable,
  },
})
export default class App extends Vue {}
