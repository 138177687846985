


































































































































































































import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { SummaryViewModel } from '../viewmodel/summary-viewmodel'

@Observer
@Component({
  components: {},
})
export default class FundedProjectTable extends Vue {
  @Inject() vm!: SummaryViewModel
}
